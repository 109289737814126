<div *ngIf="isFormSubmitting"
    style="width: 100%; height: 100%; position: fixed; background: #dddddd66; z-index: 200; top: 0;">
    <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <i class="fa fa-spinner fa-2x fa-spin"></i>
        Loading...</span>
</div>
<clinic-layout [title]="'Create consultation'">
    <form #registerUserForm="ngForm" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
        <h4 class="lable-text">1. GP details</h4>
        <div class="gp-details-section">
            <div class="row">
                <div class="col-xs-12 col-sm-6 form-group form-group-lg"
                    [style]="hasGPInUK === 'false' ? 'margin-bottom: 0;' : ''">
                    <label for="hasGPInUK">1.1.&nbsp;&nbsp;Are you registered with a GP surgery? <span
                            class="valid_message">*</span></label>
                    <div style="display: flex;">
                        <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                            <input type="radio" name="hasGPInUK" id="Yes" [(ngModel)]="hasGPInUK" value="true" required
                                style="margin-top: auto; margin-bottom: auto;">
                            <label for="Yes"
                                style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">Yes</label>
                        </div>
                        <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                            <input type="radio" name="hasGPInUK" id="No" [(ngModel)]="hasGPInUK" value="false" required
                                style="margin-top: auto; margin-bottom: auto;" (click)="selectSurgery(undefined)">
                            <label for="No"
                                style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">No</label>
                        </div>
                    </div>
                    <span *ngIf="hasGPInUK === 'false'" class="valid_message">
                        It is mandatory registered GP surgery to be eligible for this service.
                    </span>
                </div>
            </div>

            <div *ngIf="hasGPInUK === 'true'">
                <div class="gp-select-options">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <div style="display: flex;">
                                <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                    <input type="radio" name="showGPAddress" id="search-gp" [(ngModel)]="showGPAddress"
                                        value="false" required style="margin-top: auto; margin-bottom: auto;"
                                        (click)="selectSurgery(undefined)">
                                    <label for="search-gp"
                                        style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">
                                        Search GP
                                    </label>
                                </div>
                                <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                    <input type="radio" name="showGPAddress" id="enter-manually"
                                        [(ngModel)]="showGPAddress" value="true" required
                                        style="margin-top: auto; margin-bottom: auto;"
                                        (click)="selectSurgery(undefined)">
                                    <label for="enter-manually"
                                        style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">
                                        Enter GP information manually
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label *ngIf="showGPAddress === 'false'" for="gpNamePostcodeInput"
                        style="margin-bottom: 1rem; font-size: 1.5rem; font-weight: 500;">
                        Find your GP surgery
                    </label>
                    <div *ngIf="showGPAddress === 'false'" class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <div style="display: flex;">
                                <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                    <input type="radio" name="searchGPByPostcode" id="name"
                                        [(ngModel)]="searchGPByPostcode" value="false" required
                                        style="margin-top: auto; margin-bottom: auto;"
                                        (click)="selectSurgery(undefined)">
                                    <label for="name"
                                        style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">
                                        Search by Name
                                    </label>
                                </div>
                                <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                    <input type="radio" name="searchGPByPostcode" id="postcode"
                                        [(ngModel)]="searchGPByPostcode" value="true" required
                                        style="margin-top: auto; margin-bottom: auto;"
                                        (click)="selectSurgery(undefined)">
                                    <label for="postcode"
                                        style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">
                                        Search by Postcode
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="showGPAddress === 'false'" class="row">
                    <div *ngIf="searchGPByPostcode === 'false'" class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <input #gpSearchElement name="gpNamePostcodeInput" [(ngModel)]="gpNamePostcodeInput" type="text"
                            (input)="onGpInput($event.target.value)" class="form-control"
                            [disabled]="isFormSubmitting || gpAddressSearching" placeholder="Enter surgery name"
                            autocomplete="off" />
                        <span class="valid_message"
                            *ngIf="registerUserForm.submitted && !gpNamePostcodeInput && !(gpPractice && gpAddress.postcode && gpAddress.line1 && gpAddress.city)">
                            Please enter your surgery name
                        </span>
                    </div>
                    <div *ngIf="searchGPByPostcode === 'true'" class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <input #gpSearchElement="ngModel" name="gpNamePostcodeInput" type="text" required
                            [(ngModel)]="gpNamePostcodeInput" [typeaheadAsync]="true" [typeahead]="gpPostcodes"
                            [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                            typeaheadSingleWords="true" (typeaheadOnSelect)="onGpInput($event.value)"
                            (input)="onGPPostcodeInput($event.target.value)" class="form-control"
                            [disabled]="isFormSubmitting || gpAddressSearching" placeholder="Enter surgery postcode"
                            autocomplete="off" />
                        <span *ngIf="gpPostcodeSearching">
                            <i class="fa fa-spinner fa-2x fa-spin"></i>
                            Loading...</span>
                        <span class="valid_message"
                            *ngIf="registerUserForm.submitted && !gpNamePostcodeInput && !(gpPractice && gpAddress.postcode && gpAddress.line1 && gpAddress.city)">
                            Please enter your surgery postcode
                        </span>
                    </div>
                </div>
                <span *ngIf="gpAddressSearching">
                    <i class="fa fa-spinner fa-2x fa-spin"></i>
                    Loading...</span>
                <div *ngIf="gpSurgeries?.length > 0 && showGPAddress === 'false'" class="form-group mt-3">
                    <label class="lable-text">1.2.&nbsp;&nbsp;Select your registered GP surgery <span
                            class="valid_message">*</span></label>
                    <div class="surgery-info-container mt-2">
                        <div class="surgery-info mt-1" *ngFor="let surgery of gpSurgeries"
                            (click)="selectSurgery(surgery)"
                            [ngClass]="{ 'surgery-info-selected': selectedSurgeryId == surgery.id }">
                            <h3>{{surgery.name}}</h3>
                            <a class="pt-1" href="tel:+44{{surgery.mobile | slice:1}}">+44{{surgery.mobile |
                                slice:1}}</a>
                            <p>{{surgery.displayAddress}}</p>
                        </div>
                    </div>
                    <span class="valid_message"
                        *ngIf="registerUserForm.submitted && !(gpAddress.postcode && gpPractice) && gpSurgeries.length > 0">
                        Please select an option
                    </span>
                </div>
                <div *ngIf="showGPAddress === 'true'">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpPractice" class="lable-text">1.2.&nbsp;&nbsp;Practice Name <span
                                    class="valid_message">*</span></label>
                            <input id="gpPractice" name="gpPractice" type="text" required [(ngModel)]="gpPractice"
                                class="form-control" [disabled]="isFormSubmitting" />
                            <span class="valid_message" *ngIf="registerUserForm.submitted && !gpPractice">
                                Please enter your GP's Practice Name
                            </span>
                        </div>

                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpAddressPostcode" class="lable-text">1.3.&nbsp;&nbsp;GP Postcode <span
                                    class="valid_message">*</span></label>
                            <input #gpPostcodeInput="ngModel" name="gpPostcodeInput" type="text" required
                                [(ngModel)]="gpAddress.postcode" class="form-control" [disabled]="isFormSubmitting" />
                            <span class="valid_message" *ngIf="registerUserForm.submitted && !gpAddress.postcode">
                                Please enter your GP's Postcode
                            </span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpAddressLine1" class="lable-text">1.4.&nbsp;&nbsp;GP Address Line 1 <span
                                    class="valid_message">*</span></label>
                            <input id="gpAddressLine1" name="gpAddressLine1" type="text" required
                                [(ngModel)]="gpAddress.line1" class="form-control" [disabled]="isFormSubmitting" />
                            <span class="valid_message" *ngIf="registerUserForm.submitted && !gpAddress.line1">
                                please enter your GP's address
                            </span>
                        </div>

                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpAddressLine2" class="lable-text">1.5.&nbsp;&nbsp;GP Address Line 2</label>
                            <input id="gpAddressLine2" name="gpAddressLine2" type="text" [(ngModel)]="gpAddress.line2"
                                class="form-control" [disabled]="isFormSubmitting" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpAddressCity" class="lable-text">1.6.&nbsp;&nbsp;GP Town <span
                                    class="valid_message">*</span></label>
                            <input id="gpAddressCity" name="gpAddressCity" type="text" required
                                [(ngModel)]="gpAddress.city" class="form-control" [disabled]="isFormSubmitting" />
                            <span class="valid_message" *ngIf="registerUserForm.submitted && !gpAddress.city">
                                Please enter your GP's city
                            </span>
                        </div>

                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="gpAddressCounty" class="lable-text">1.7.&nbsp;&nbsp;GP County</label>
                            <input id="gpAddressCounty" name="gpAddressCounty" type="text"
                                [(ngModel)]="gpAddress.county" class="form-control" [disabled]="isFormSubmitting" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(gpPractice && gpAddress.postcode && gpAddress.line1 && gpAddress.city)">
            <h4 #patientDetailsHeader class="lable-text" style="margin-top: 3rem;">2. Patient details</h4>
            <div class="patient-details-section">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="email">2.1.&nbsp;&nbsp;Email <span class="valid_message">*</span></label>
                        <input #emailInputField id="email" name="email" type="email" required [(ngModel)]="email"
                            class="form-control" [disabled]="isFormSubmitting" email="true"
                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !email">
                            Please enter the email
                        </span>
                        <span
                            *ngIf="registerUserForm.controls.email.status === 'INVALID' && registerUserForm.controls.email.touched"
                            class="valid_message">
                            Please enter a valid email
                        </span>
                    </div>
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="phone">2.2.&nbsp;&nbsp;Contact Number <span class="valid_message">*</span></label>
                        <input id="phone" name="phone" type="tel" required [(ngModel)]="phone" class="form-control"
                            placeholder="+44 XXXXXXXXXX" [disabled]="isFormSubmitting" />
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !phone">
                            Please enter the phone number
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="firstName">2.3.&nbsp;&nbsp;First Name <span class="valid_message">*</span></label>
                        <input id="firstName" name="firstName" type="text" required [(ngModel)]="firstName"
                            class="form-control" [disabled]="isFormSubmitting" />
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !firstName">
                            Please enter the first name
                        </span>
                    </div>
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="lastName">2.4.&nbsp;&nbsp;Last Name <span class="valid_message">*</span></label>
                        <input id="lastName" name="lastName" type="text" required [(ngModel)]="lastName"
                            class="form-control" [disabled]="isFormSubmitting" />
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !lastName">
                            Please enter the last name
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="gender">2.5.&nbsp;&nbsp;Sex at Birth <span class="valid_message">*</span></label>
                        <div style="display: flex; height: 46px;">
                            <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="gender" id="MALE" [(ngModel)]="gender" value="MALE" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="MALE"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">MALE</label>
                            </div>
                            <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="gender" id="FEMALE" [(ngModel)]="gender" value="FEMALE"
                                    required style="margin-top: auto; margin-bottom: auto;">
                                <label for="FEMALE"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">FEMALE</label>
                            </div>
                        </div>
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !gender">
                            Please select the gender at birth
                        </span>
                    </div>
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="dobDay">2.6.&nbsp;&nbsp;Date of Birth <span class="valid_message">*</span></label>
                        <div class="input-group" style="width: 100%;">
                            <select style="width: calc(30% - 8px); margin-right: 8px;" id="dobDay" name="dobDay"
                                required [(ngModel)]="dobDay" class="form-control" [disabled]="isFormSubmitting"
                                (change)="onChangeDob()">
                                <option disabled [value]="null">Day</option>
                                <option *ngFor="let day of days" [value]="day">{{day}}</option>
                            </select>
                            <select style="width: calc(40% - 8px); margin-right: 8px;" id="dobMonth" name="dobMonth"
                                required [(ngModel)]="dobMonth" class="form-control" [disabled]="isFormSubmitting"
                                (change)="onChangeDob()">
                                <option disabled [value]="null">Month</option>
                                <option *ngFor="let month of months; index as i" [value]="i+1">{{month}}</option>
                            </select>
                            <select style="width: 30%;" id="dobYear" name="dobYear" required [(ngModel)]="dobYear"
                                class="form-control" [disabled]="isFormSubmitting" (change)="onChangeDob()">
                                <option disabled [value]="null">Year</option>
                                <option *ngFor="let year of years" [value]="year">{{year}}</option>
                            </select>
                        </div>
                        <span class="valid_message"
                            *ngIf="registerUserForm.submitted && !(dobDay && dobMonth && dobYear)">
                            Please select date of birth
                        </span>
                        <span class="valid_message" *ngIf="dob21Error">
                            Patient should be at least 18 years old
                        </span>
                        <span class="valid_message" *ngIf="invalidDayError">
                            Please select a valid day
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="cannabisCardId">2.7.&nbsp;&nbsp;Cannabis Card ID (optional)</label>
                        <input id="cannabisCardId" name="cannabisCardId" type="text" [(ngModel)]="cannabisCardId"
                            list="cannabisCardIds" class="form-control" (input)="suggestValues()" mask="000-000-000"
                            [disabled]=" isFormSubmitting" />
                        <datalist id="cannabisCardIds">
                            <option *ngFor="let id of cannabisCardIds" [value]="id">{{id}}</option>
                        </datalist>
                    </div>
                </div>

                <h4 style="margin-bottom: 0;" class="lable-text">Address</h4>
                <h5 class="lable-text" style="margin-top: 0.25rem;">
                    Your prescription will be delivered to this address
                </h5>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="addressPostcode">2.8.&nbsp;&nbsp;Postcode <span
                                class="valid_message">*</span></label>
                        <input #patientPostcodeInput="ngModel" name="patientPostcodeInput" type="text" required
                            [(ngModel)]="userAddress.postcode" [typeaheadAsync]="true" [typeahead]="userPostcodes"
                            [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                            typeaheadSingleWords="true" (typeaheadOnSelect)="onUserPostcodeSelect($event.value)"
                            (input)="onUserPostcodeInput($event.target.value)" class="form-control"
                            [disabled]="isFormSubmitting" />
                        <span *ngIf="userPostcodeSearching">
                            <i class="fa fa-spinner fa-2x fa-spin"></i>
                            Loading...</span>
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !userAddress.postcode">
                            Please enter patient postcode
                        </span>
                    </div>

                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="userAddress">2.9.&nbsp;&nbsp;Address <span class="valid_message">*</span></label>
                        <select id="userAddress" name="userAddress" class="form-control"
                            [(ngModel)]="selectedUserAddress" (ngModelChange)="updateSelectedUserAddress($event)"
                            [disabled]="isFormSubmitting || !userPostcodeSelected">
                            <option value="null" disabled></option>
                            <option value="Enter address manually">Enter address manually</option>
                            <option *ngFor="let address of userAddresses" [ngValue]="address"
                                (click)="updateSelectedUserAddress(address)">
                                {{ address.displayAddress }}
                            </option>
                        </select>
                        <span class="valid_message" *ngIf="registerUserForm.submitted && !selectedUserAddress">
                            Please select patient address
                        </span>
                    </div>
                </div>

                <div *ngIf="userAddressSelected">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="addressLine1">2.10.&nbsp;&nbsp;Address Line 1<span
                              class="valid_message">*</span></label>
                            <input id="addressLine1" name="addressLine1" type="text" [(ngModel)]="userAddress.line1"
                                class="form-control" [disabled]="isFormSubmitting" required />
                            <span class="valid_message" *ngIf="registerUserForm.submitted && !selectedUserAddress.line1">
                              Please add the patient address
                            </span>
                        </div>

                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="addressLine2">2.11.&nbsp;&nbsp;Address Line 2<span
                              class="valid_message">*</span></label>
                            <input id="addressLine2" name="addressLine2" type="text"
                                [ngModel]="userAddress.line2 ? userAddress.line2 : ''"
                                (ngModelChange)="userAddress.line2 = $event" class="form-control"
                                [disabled]="isFormSubmitting" required />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="addressCity">2.12.&nbsp;&nbsp;Town<span
                              class="valid_message">*</span></label>
                            <input id="addressCity" name="addressCity" type="text" [(ngModel)]="userAddress.city"
                                class="form-control" [disabled]="isFormSubmitting" required />
                          <span class="valid_message" *ngIf="registerUserForm.submitted && !selectedUserAddress.city">
                              Please add the patient address
                            </span>
                        </div>

                        <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                            <label for="addressCounty">2.13.&nbsp;&nbsp;County</label>
                            <input id="addressCounty" name="addressCounty" type="text" [(ngModel)]="userAddress.county"
                                class="form-control" [disabled]="isFormSubmitting" />
<!--                          <span class="valid_message" *ngIf="registerUserForm.submitted && !selectedUserAddress.county">-->
<!--                              Please add the patient address-->
<!--                            </span>-->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12 form-group form-group-lg">
                        <label for="identifiedByPrescribingPharmacist">
                            2.14.&nbsp;&nbsp;Have you verified the customer's identity against their government-approved
                            ID? <span class="valid_message">*</span>
                        </label>
                        <div style="display: flex; height: 46px;">
                            <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="identifiedByPrescribingPharmacist"
                                    id="identifiedByPrescribingPharmacist-true"
                                    [(ngModel)]="identifiedByPrescribingPharmacist" value="true" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="identifiedByPrescribingPharmacist-true"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">Yes</label>
                            </div>
                            <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="identifiedByPrescribingPharmacist"
                                    id="identifiedByPrescribingPharmacist-false"
                                    [(ngModel)]="identifiedByPrescribingPharmacist" value="false" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="identifiedByPrescribingPharmacist-false"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">No</label>
                            </div>
                        </div>
                        <span class="valid_message"
                            *ngIf="registerUserForm.submitted && !identifiedByPrescribingPharmacist">
                            Please select an option
                        </span>
                    </div>
                </div>

                <!-- How did you hear about us? -->
                <div class="row">
                    <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                        <label for="identifiedByPrescribingPharmacist">
                            2.15.&nbsp;&nbsp;How did you hear about us? <span class="valid_message">*</span>
                        </label>
                        <select id="referredBy" name="referredBy" required class="form-control" (change)="onReferredBySelectChange()"
                                [(ngModel)]="referredBy" [disabled]="isFormSubmitting"
                        >
                          <option value="null" disabled>Please select option</option>
                          <option value="facebook"  >Facebook</option>
                          <option value="instagram">Instagram</option>
                          <option value="tiktok" >Tiktok</option>
                          <option value="search" >Search</option>
                          <option value="google_ads" >Google Ads/Online Ads</option>
                          <option value="email" >Email</option>
                          <option value="referral" >Referral</option>

                          <option value="website" >Website</option>
                          <option value="instore_ads" >InStore Ad</option>
                          <option value="flyer" >Flyer</option>
                          <option value="online_forum" >Online forum</option>
                          <option value="seminar-webinar" >Seminar-webinar</option>
                          <option value="sales_call" >Sales call</option>

                          <option value="other" >Other</option>
                        </select>
                        <span class="valid_message"
                        *ngIf="registerUserForm.submitted && !referredBy">
                          Please select an option
                        </span>
                    </div>
                  <div class="col-xs-12 col-sm-6 form-group form-group-lg" *ngIf="referredBy === 'other'">
                    <label for="otherField">
                      2.16.&nbsp;&nbsp;Specify the other option <span class="valid_message">*</span>
                    </label>
                    <input type="text" id="otherField" name="otherField"
                           [disabled]="referredBy === 'other' && isFormSubmitting" class="form-control" [(ngModel)]="otherFieldValue"
                    >
                    <span class="valid_message"
                      *ngIf="registerUserForm.submitted && !otherFieldValue">
                                Please specify the other option
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xs-12 col-sm-6 form-group form-group-lg">
                    <label for="medicalConditionType">
                      2.17.&nbsp;&nbsp;What type of condition are you struggling with? <span class="valid_message">*</span>
                    </label>
                    <select id="medicalConditionType" name="medicalConditionType" required class="form-control"
                            [(ngModel)]="medicalConditionType" [disabled]="isFormSubmitting"
                    >
                      <option value="null" disabled>Please select option</option>
                      <option value="Pain">Pain</option>
                      <option value="Cancer Related Symptoms">Cancer Related Symptoms</option>
                      <option value="Mental Health">Mental Health</option>
                    </select>
                    <span class="valid_message"
                      *ngIf="registerUserForm.submitted && !medicalConditionType">
                        Please select an option
                    </span>
                  </div>
                </div>

            </div>

            <h4 class="lable-text" style="margin-top: 3rem;">3. Consent</h4>
            <div class="consent-section" style="margin-bottom: 3rem;">
                <div class="row">
                    <div class="col-xs-12 form-group form-group-lg">
                        <label for="confirmAccessNHS">
                            3.1.&nbsp;&nbsp;Do you consent to our pharmacists accessing your Summary Care
                            Records for providing personalized and safe medication management?
                            <span class="valid_message">*</span>
                        </label>
                        <div style="display: flex;">
                            <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="confirmAccessNHS" id="confirmAccessNHS-Yes"
                                    [(ngModel)]="confirmAccessNHS" value="true" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="confirmAccessNHS-Yes"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">Yes</label>
                            </div>
                            <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="confirmAccessNHS" id="confirmAccessNHS-No"
                                    [(ngModel)]="confirmAccessNHS" value="false" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="confirmAccessNHS-No"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">No</label>
                            </div>
                        </div>
                        <span class="valid_message"
                            *ngIf="(registerUserForm.submitted && !confirmAccessNHS) || confirmAccessNHS === 'false'">
                            We respect your decision. However, please note that without this consent, we are unable to
                            provide this specific service due to the necessity of accessing your Summary Care Records
                            for safe and tailored medication management
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 form-group form-group-lg">
                        <label for="shareConsultationWithGP">3.2.&nbsp;&nbsp;Do you consent to us sharing GP notes with
                            your
                            registered surgery or General Practitioner for enhanced healthcare coordination? While this
                            is not mandatory, please note that not giving consent may limit our ability to offer fully
                            integrated and informed medical care
                        </label>
                        <div style="display: flex;">
                            <div style="margin-right: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="shareConsultationWithGP" id="shareConsultationWithGP-Yes"
                                    [(ngModel)]="shareConsultationWithGP" value="true" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="shareConsultationWithGP-Yes"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">Yes</label>
                            </div>
                            <div style="margin-left: 1rem; display: flex; margin-top: auto; margin-bottom: auto;">
                                <input type="radio" name="shareConsultationWithGP" id="shareConsultationWithGP-No"
                                    [(ngModel)]="shareConsultationWithGP" value="false" required
                                    style="margin-top: auto; margin-bottom: auto;">
                                <label for="shareConsultationWithGP-No"
                                    style="margin-left: 0.5rem; margin-top: auto; margin-bottom: auto;">No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="gpPractice && gpAddress.postcode && gpAddress.line1 && gpAddress.city && !registerUserForm.invalid && confirmAccessNHS === 'true'"
            class="submit-button-container">
            <button class="btn btn-primary" type="submit" style="width: 25%; margin: auto; min-width: 20rem;"
                [disabled]="isFormSubmitting">
                Create Patient
            </button>
        </div>
        <div *ngIf="hasGPInUK === 'false' || confirmAccessNHS === 'false'" style="display: flex; margin-top: 5rem;">
            <button class="btn btn-primary" type="button" routerLink="/clinic">
                Exit
            </button>
        </div>
    </form>
</clinic-layout>